<template>
  <v-dialog
    v-model="meetStore.meeting"
    max-width="900"
    theme="dark"
    persistent
    scrim="#000"
    opacity="0.7"
    :class="{ 'd-none': meetStore.minimized }"
    scroll-strategy="none"
  >
    <v-card rounded="lg" :loading="loading">
      <div class="position-relative">
        <CaseQuestion
          v-if="question"
          ref="qRef"
          :question="question"
          :subtitle="showSubtitle"
        />
        <v-sheet
          v-else
          min-height="300px"
          class="d-flex align-center justify-center"
        >
          <template v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
            ></v-progress-circular>
          </template>
          <template v-else>
            <div class="text-disabled text-body-2">Soru yok.</div>
          </template>
        </v-sheet>
        <v-btn
          theme="dark"
          elevation="10"
          border
          class="text-none text-subtitle-2 position-absolute right-0 top-0 ma-2"
          rounded="lg"
          @click="handleMinimizeWindow"
          icon="mdi-minus"
          size="small"
        ></v-btn>
      </div>
      <v-card-actions class="pa-4 d-flex align-center justify-center">
        <div class="d-flex justify-start flex-1-1-0">
          <v-sheet v-if="meetStore.info" rounded="lg" class="px-3 py-2" border>
            <MeetCountdown class="text-subtitle-2 font-weight-bold" />
          </v-sheet>
        </div>
        <div class="d-flex align-center justify-center ga-4">
          <v-btn
            border
            class="text-none text-subtitle-2"
            @click="replay"
            icon="mdi-replay"
          >
          </v-btn>
          <v-btn
            border
            :active="showSubtitle"
            class="text-none text-subtitle-2"
            @click="showSubtitle = !showSubtitle"
            icon="mdi-subtitles"
          ></v-btn>
        </div>
        <div class="d-flex justify-end flex-1-1-0">
          <v-btn
            rounded="lg"
            variant="flat"
            color="secondary"
            class="text-none text-subtitle-2"
            @click="nextQuestion"
            append-icon="mdi-arrow-right"
            >Sonraki Soru</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import CaseQuestion from "./CaseQuestion.vue";
import MeetCountdown from "./MeetCountdown.vue";

const client = useSanctumClient();
const meetStore = useMeetStore();

const { transcript, resetTranscript, partsTranscript } = useSpeechRecognition({
  transcribing: true,
  clearTranscriptOnListen: true,
});

const question = ref();
const loading = ref(false);
const showSubtitle = ref(false);
const qRef = ref();

async function loadQuestion() {
  loading.value = true;
  const res = await client(`/api/applicant/case/qa/question`, {
    method: "POST",
    body: { exam_id: meetStore.info.exam_id },
  }).finally(() => {
    loading.value = false;
  });

  question.value = res.data;
}

function replay() {
  qRef.value.replay();
}

async function nextQuestion() {
  await client("/api/applicant/case/qa/question/answer", {
    method: "POST",
    body: {
      exam_id: meetStore.info.examId,
      question_id: question.value.id,
      answer: transcript.value,
      transcript: partsTranscript.value,
    },
  });

  await loadQuestion();

  resetTranscript();
}

function handleMinimizeWindow() {
  meetStore.minimized = true;
}

watch(
  () => meetStore.info,
  () => {
    if (meetStore.info) {
      loadQuestion();
    }
  },
  { immediate: true }
);
</script>

<style></style>
