import { EventEmitter } from "events";
import LocalParticipant from "./LocalParticipant";
import SpeechRecognition from "../speech-recognition/SpeechRecognition";

export default class Meet extends EventEmitter {
  constructor() {
    super();
    this.state = "idle";
    this.meetInfo = null;

    this.localParticipant = new LocalParticipant();

    this.cameraCaptureStopFunc = null;
    this.screenCaptureStopFunc = null;
    this.captureAudioLevelStopFunc = null;
  }

  async startMeeting(meetInfo) {
    if (this.state == "connected") {
      console.log("Already connected");
      return Promise.resolve();
    }

    this.setAndEmitState("connecting");

    if (meetInfo.status == "waiting") {
      // await client(`/api/applicant/case/meet/${meetInfo.id}/join`, {
      //   method: "POST",
      // });
    }

    if (meetInfo.status == "ended") {
      this.setAndEmitState("ended");
      return;
    }

    this.meetInfo = meetInfo;

    await this.localParticipant.setMicrophoneEnabled(true);
    await this.localParticipant.setCameraEnabled(true);

    this.setAndEmitState("connected");
  }

  setAndEmitState(state) {
    if (this.state == state) return;

    this.state = state;
    this.emit("stateChanged", state);
    return true;
  }

  async start(options = {}) {
    if (options.duration > 0) {
      setTimeout(() => {
        this.stop();
      }, options.duration);
    }

    try {
      const microphoneTrack = await this.localParticipant.setMicrophoneEnabled(
        true
      );
      const cameraTrack = await this.localParticipant.setCameraEnabled(true);
      // const screenTrack = await this.localParticipant.setScreenShareEnabled(
      //   true
      // );

      // this.cameraCaptureStopFunc = await this.captureBase64ImagesFromTrack(
      //   cameraTrack,
      //   10000
      // );

      // this.screenCaptureStopFunc = await this.captureBase64ImagesFromTrack(
      //   screenTrack,
      //   1000
      // );

      this.captureAudioLevelStopFunc = await this.captureAudioLevelFromTrack(
        microphoneTrack,
        50
      );

      await SpeechRecognition.startListening({
        continuous: true,
        language: "tr-TR",
      });

      this.status = "started";
      this.emit("started");
    } catch (e) {
      this.stop();
    }
  }

  async stop() {
    await this.localParticipant.setCameraEnabled(false);
    await this.localParticipant.setMicrophoneEnabled(false);
    await this.localParticipant.setScreenShareEnabled(false);

    await SpeechRecognition.stopListening();

    if (this.cameraCaptureStopFunc) {
      this.cameraCaptureStopFunc();
    }

    if (this.screenCaptureStopFunc) {
      this.screenCaptureStopFunc();
    }

    if (this.captureAudioLevelStopFunc) {
      this.captureAudioLevelStopFunc();
    }
  }

  async captureBase64ImagesFromTrack(videoTrack, interval = 1000) {
    const video = document.createElement("video");
    videoTrack.attach(video);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    await new Promise((resolve) => {
      video.onloadedmetadata = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        resolve();
      };
    });

    const intervalId = setInterval(() => {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const base64Image = canvas.toDataURL("image/png");
      console.log("Captured Base64 Image:", base64Image);
    }, interval);

    return () => {
      clearInterval(intervalId);
      videoTrack.detach(video);
      video.remove();
    };
  }

  async captureAudioLevelFromTrack(audioTrack, interval = 1000) {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const mediaStreamSource = audioContext.createMediaStreamSource(
      new MediaStream([audioTrack.mediaStreamTrack])
    );
    const analyser = audioContext.createAnalyser();
    mediaStreamSource.connect(analyser);
    analyser.fftSize = 256;
    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    const intervalId = setInterval(() => {
      analyser.getByteFrequencyData(dataArray);
      let sum = 0;
      for (let i = 0; i < dataArray.length; i++) {
        sum += dataArray[i];
      }
      const average = sum / dataArray.length;
      const audioLevel = average / 128;
      this.emit("audioLevel", audioLevel);
    }, interval);

    return () => {
      clearInterval(intervalId);
      mediaStreamSource.disconnect();
      analyser.disconnect();
      audioContext.close();
      this.emit("audioLevel", 0);
    };
  }
}
