<template>
  <div v-if="meetStore.meeting">
    <MeetController />
    <MeetPipView />
    <MeetDialog />
  </div>
</template>

<script setup>
import MeetController from "./MeetController.vue";
import MeetPipView from "./MeetPipView.vue";
import MeetDialog from "./MeetDialog.vue";

const meetStore = useMeetStore();
</script>
