<template>
  <div class="position-relative rounded-lg d-flex align-center bg-black h-100">
    <div style="aspect-ratio: 16/9; max-width: 100%" class="d-flex h-100 w-100">
      <video
        ref="videoEl"
        class="w-100 h-auto"
        style="max-height: 100%; max-width: 100%"
        :src="`http://hire_backend.test/storage/case/1/question/${question.id}.mp4`"
        autoplay
        @play="onPlay"
        @pause="onPause"
        @ended="onEnded"
      />
    </div>
    <v-sheet
      v-if="subtitle"
      position="absolute"
      location="top center"
      theme="dark"
      class="mt-5 px-5 py-3"
      style="width: 85%; background-color: rgba(0, 0, 0, 0.7)"
      rounded="lg"
    >
      {{ question.question }}
    </v-sheet>
    <v-scale-transition>
      <div
        v-if="videoStatus == 'ended'"
        class="d-flex justify-center py-10 position-absolute bottom-0 w-100"
      >
        <SpeakingIndicator />
      </div>
    </v-scale-transition>
    <v-sheet
      position="absolute"
      location="bottom right"
      rounded="lg"
      elevation="10"
      class="overflow-hidden ma-4"
      style="width: 20%"
    >
      <MeetLocalCamera size="100%" />
    </v-sheet>
    <v-sheet
      position="absolute"
      location="bottom left"
      rounded="lg"
      class="px-3 py-2 ma-4 text-subtitle-2 text-center"
      elevation="8"
    >
      {{ userName }}
    </v-sheet>
    <slot />
  </div>
</template>

<script setup>
import SpeakingIndicator from "./SpeakingIndicator.vue";
import MeetLocalCamera from "./MeetLocalCamera.vue";

defineProps({
  question: Object,
  subtitle: {
    type: Boolean,
    default: true,
  },
});

const meetStore = useMeetStore();

const videoEl = ref();
const videoStatus = ref("paused");

const userName = computed(() => meetStore.info?.title);

function replay() {
  if (!videoEl.value) return;

  videoEl.value.currentTime = 0;
  videoEl.value.play();
  videoStatus.value = "playing";
}

function onPlay() {
  videoStatus.value = "playing";
}

function onPause() {
  videoStatus.value = "paused";
}

function onEnded() {
  videoStatus.value = "ended";
}

defineExpose({ replay, videoStatus });
</script>

<style></style>
