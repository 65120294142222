<template>
  <span v-if="!isCompleted">
    {{ timeFormatted }}
  </span>
  <span v-else>
    <slot name="completed"> {{ timeFormatted }} </slot>
  </span>
</template>

<script setup>
const props = defineProps({
  targetDate: {
    type: [String, Date],
    required: false,
  },
  autoStart: {
    type: Boolean,
    default: true,
  },
  format: {
    type: String,
    default: "hh:mm:ss",
  },
});

const emit = defineEmits(["end"]);

const targetDate = toRef(props, "targetDate");

const { timeFormatted, isCompleted } = useCountdown(
  targetDate,
  props.autoStart,
  props.format
);

watch(
  isCompleted,
  (val) => {
    if (val) {
      emit("end");
    }
  },
  { immediate: true }
);
</script>

<style></style>
