<template>
  <Teleport to="body">
    <v-scale-transition>
      <v-sheet
        v-show="meet.minimized"
        position="fixed"
        location="bottom right"
        style="z-index: 999"
        class="mb-6 mr-6 rounded-lg overflow-hidden"
        theme="dark"
        elevation="8"
        rounded="lg"
        @click="meet.minimized = false"
      >
        <MeetLocalCamera size="190px" show-username />
      </v-sheet>
    </v-scale-transition>
  </Teleport>
</template>

<script setup>
const meet = useMeetStore();
</script>

<style></style>
