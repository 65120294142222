import { mdi } from "vuetify/iconsets/mdi";
import { defineNuxtPlugin } from "#imports";
import type { IconSet, IconProps } from "vuetify";
import { Icon } from "@iconify/vue";
import { tr } from "vuetify/locale";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("vuetify:configuration", ({ vuetifyOptions }) => {
    vuetifyOptions.locale = {
      locale: "tr",
      messages: { tr },
      fallback: "tr",
    };
  });

  const iconify: (set: string) => IconSet = (set) => ({
    component: (props: IconProps) =>
      h(Icon, {
        icon: `${set}:${props.icon}`,
        disabled: props.disabled,
      }),
  });

  nuxtApp.hook("vuetify:before-create", ({ vuetifyOptions }) => {
    vuetifyOptions.icons = {
      defaultSet: "mdi",
      sets: {
        mdi,
        flags: iconify("flags"),
        heroicons: iconify("heroicons"),
        "heroicons-outline": iconify("heroicons-outline"),
        "heroicons-solid": iconify("heroicons-solid"),
        "mdi-light": iconify("mdi-light"),
        fa: iconify("fa"),
        "fa-solid": iconify("fa-solid"),
        lucide: iconify("lucide"),
        "fa6-solid": iconify("fa6-solid"),
        "fa6-regular": iconify("fa6-regular"),
        "material-symbols-light": iconify("material-symbols-light"),
      },
    };
  });
});
