import { concatTranscripts } from "~/utils/speech-recognition/utils";
import SpeechRecognition from "~/utils/speech-recognition/SpeechRecognition";

export const useSpeechRecognition = ({
  transcribing = true,
  clearTranscriptOnListen = true,
} = {}) => {
  const recognitionManager = SpeechRecognition.getRecognitionManager();
  const browserSupportsSpeechRecognition = ref(
    SpeechRecognition.browserSupportsSpeechRecognition()
  );
  const browserSupportsContinuousListening = ref(
    SpeechRecognition.browserSupportsContinuousListening()
  );

  const listening = ref(recognitionManager.listening);
  const isMicrophoneAvailable = ref(recognitionManager.isMicrophoneAvailable);
  const interimTranscript = ref(recognitionManager.interimTranscript);
  const finalTranscript = ref("");
  const partsTranscript = ref([]);
  let subscribeId = null;

  const transcript = computed(() =>
    concatTranscripts(finalTranscript.value, interimTranscript.value)
  );

  const subscribe = () => {
    SpeechRecognition.counter += 1;
    subscribeId = SpeechRecognition.counter;

    let startTime = null;
    const callbacks = {
      onListeningChange: (value) => {
        listening.value = value;
      },
      onMicrophoneAvailabilityChange: (value) => {
        isMicrophoneAvailable.value = value;
      },
      onTranscriptChange: (newInterimTranscript, newFinalTranscript) => {
        if (transcribing) {
          if (!startTime) {
            startTime = new Date();
          }

          interimTranscript.value = newInterimTranscript;
          finalTranscript.value = concatTranscripts(
            finalTranscript.value,
            newFinalTranscript
          );
          if (newFinalTranscript) {
            partsTranscript.value.push({
              t: newFinalTranscript,
              s: startTime,
              d: new Date() - startTime,
            });
            startTime = null;
          }
        }
      },
      onClearTranscript: () => {
        if (clearTranscriptOnListen) {
          interimTranscript.value = "";
          finalTranscript.value = "";
          partsTranscript.value = [];
        }
      },
      onBrowserSupportsSpeechRecognitionChange: (value) => {
        browserSupportsSpeechRecognition.value = value;
      },
      onBrowserSupportsContinuousListeningChange: (value) => {
        browserSupportsContinuousListening.value = value;
      },
    };

    recognitionManager.subscribe(subscribeId, callbacks);
  };

  const unsubscribe = () => {
    if (subscribeId) {
      recognitionManager.unsubscribe(subscribeId);
    }
  };

  const resetTranscript = () => {
    recognitionManager.resetTranscript();
    interimTranscript.value = "";
    finalTranscript.value = "";
    partsTranscript.value = [];
  };

  onMounted(() => {
    subscribe();
  });

  onUnmounted(() => {
    unsubscribe();
  });

  return {
    transcript,
    interimTranscript,
    finalTranscript,
    partsTranscript,
    listening,
    isMicrophoneAvailable,
    resetTranscript,
    browserSupportsSpeechRecognition,
    browserSupportsContinuousListening,
    manager: recognitionManager,
  };
};
