<template>
  <div class="audio-level-circle-wrapper">
    <div class="audio-level-circle d-flex justify-center align-center">
      <div class="audio-level-circle-inner" :style="circleStyle"></div>
      <v-icon size="x-large" color="white">mdi-microphone</v-icon>
    </div>
  </div>
</template>

<script setup>
const audioLevel = useAudioLevel();

const circleStyle = computed(() => {
  const size = Math.min(30, Math.max(5, audioLevel.value * 100));
  return {
    width: `${70 + size}px`,
    height: `${70 + size}px`,
  };
});
</script>

<style>
.audio-level-circle {
  transition: width 0.4s, height 0.4s;
  width: 70px;
  height: 70px;
  background-color: rgb(141, 186, 253);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.audio-level-circle-wrapper {
  position: relative;
  display: inline-block;
}

.audio-level-circle-inner {
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(33, 122, 255, 0.7);
}
</style>
