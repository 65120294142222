import { Track } from "./Track";

const commonVersionIdentifier = /version\/(\d+(\.?_?\d+)+)/i;
let browserDetails;

export function getBrowser(userAgent, force = true) {
  if (typeof userAgent === "undefined" && typeof navigator === "undefined") {
    return;
  }
  const ua = (userAgent ?? navigator.userAgent).toLowerCase();
  if (browserDetails === undefined || force) {
    const browser = browsersList.find(({ test }) => test.test(ua));
    browserDetails = browser?.describe(ua);
  }
  return browserDetails;
}

const browsersList = [
  {
    test: /firefox|iceweasel|fxios/i,
    describe(ua) {
      const browser = {
        name: "Firefox",
        version: getMatch(
          /(?:firefox|iceweasel|fxios)[\s/](\d+(\.?_?\d+)+)/i,
          ua
        ),
        os: ua.toLowerCase().includes("fxios") ? "iOS" : undefined,
        osVersion: getOSVersion(ua),
      };
      return browser;
    },
  },
  {
    test: /chrom|crios|crmo/i,
    describe(ua) {
      const browser = {
        name: "Chrome",
        version: getMatch(
          /(?:chrome|chromium|crios|crmo)\/(\d+(\.?_?\d+)+)/i,
          ua
        ),
        os: ua.toLowerCase().includes("crios") ? "iOS" : undefined,
        osVersion: getOSVersion(ua),
      };

      return browser;
    },
  },
  /* Safari */
  {
    test: /safari|applewebkit/i,
    describe(ua) {
      const browser = {
        name: "Safari",
        version: getMatch(commonVersionIdentifier, ua),
        os: ua.includes("mobile/") ? "iOS" : "macOS",
        osVersion: getOSVersion(ua),
      };

      return browser;
    },
  },
];

function getOSVersion(ua) {
  return ua.includes("mac os")
    ? getMatch(/\(.+?(\d+_\d+(:?_\d+)?)/, ua, 1).replace(/_/g, ".")
    : undefined;
}

function getMatch(exp, ua, id = 1) {
  const match = ua.match(exp);
  return (match && match.length >= id && match[id]) || "";
}

// ----------------------------

export function isFireFox() {
  return getBrowser()?.name === "Firefox";
}

export function isSafari() {
  return getBrowser()?.name === "Safari";
}

export function isWeb() {
  return typeof document !== "undefined";
}

export function isSafari17() {
  const b = getBrowser();
  return b?.name === "Safari" && b.version.startsWith("17.");
}

export function screenCaptureToDisplayMediaStreamOptions(options) {
  let videoConstraints = options.video ?? true;
  // treat 0 as uncapped
  if (
    options.resolution &&
    options.resolution.width > 0 &&
    options.resolution.height > 0
  ) {
    videoConstraints =
      typeof videoConstraints === "boolean" ? {} : videoConstraints;
    if (isSafari()) {
      videoConstraints = {
        ...videoConstraints,
        width: { max: options.resolution.width },
        height: { max: options.resolution.height },
        frameRate: options.resolution.frameRate,
      };
    } else {
      videoConstraints = {
        ...videoConstraints,
        width: { ideal: options.resolution.width },
        height: { ideal: options.resolution.height },
        frameRate: options.resolution.frameRate,
      };
    }
  }
}

export function mediaTrackToTrack(mediaStreamTrack, constraints) {
  switch (mediaStreamTrack.kind) {
    case "audio":
      return new Track(mediaStreamTrack, "audio", constraints);
    case "video":
      return new Track(mediaStreamTrack, "video", constraints);
    default:
      throw new Error(`unsupported track type: ${mediaStreamTrack.kind}`);
  }
}

function mergeObjectWithoutOverwriting(mainObject, objectToMerge) {
  Object.keys(objectToMerge).forEach((key) => {
    if (mainObject[key] === undefined) mainObject[key] = objectToMerge[key];
  });
  return mainObject;
}

export function constraintsForOptions(options) {
  const constraints = {};

  if (options.video) {
    // default video options
    if (typeof options.video === "object") {
      const videoOptions = {};
      const target = videoOptions;
      const source = options.video;
      Object.keys(source).forEach((key) => {
        switch (key) {
          case "resolution":
            // flatten VideoResolution fields
            mergeObjectWithoutOverwriting(target, source.resolution);
            break;
          default:
            target[key] = source[key];
        }
      });
      constraints.video = videoOptions;
    } else {
      constraints.video = options.video;
    }
  } else {
    constraints.video = false;
  }

  if (options.audio) {
    if (typeof options.audio === "object") {
      constraints.audio = options.audio;
    } else {
      constraints.audio = true;
    }
  } else {
    constraints.audio = false;
  }
  return constraints;
}
