import { EventEmitter } from "events";
import {
  mediaTrackToTrack,
  isSafari17,
  screenCaptureToDisplayMediaStreamOptions,
} from "./utils";
import { Track } from "./Track";

export class TrackInvalidError extends Error {
  constructor(message) {
    super(20, message ?? "track is invalid");
  }
}

export default class LocalParticipant extends EventEmitter {
  constructor() {
    super();
    this.tracks = new Map();
    this.audioTracks = new Map();
    this.videoTracks = new Map();
    this.microphoneError = null;
    this.cameraError = null;
  }

  async createTracks(constraints) {
    console.log("createTracks", constraints);

    let stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
      if (err instanceof Error) {
        if (constraints.audio) {
          this.microphoneError = err;
        }
        if (constraints.video) {
          this.cameraError = err;
        }
      }

      throw err;
    }

    if (constraints.audio) {
      this.microphoneError = undefined;
      this.emit("audioStreamAcquired");
    }
    if (constraints.video) {
      this.cameraError = undefined;
    }

    return stream.getTracks().map((mediaStreamTrack) => {
      const isAudio = mediaStreamTrack.kind === "audio";
      let trackConstraints;
      const conOrBool = isAudio ? constraints.audio : constraints.video;
      if (typeof conOrBool !== "boolean") {
        trackConstraints = conOrBool;
      }
      const track = mediaTrackToTrack(mediaStreamTrack, trackConstraints);

      if (track.kind === "video") {
        track.source = "camera";
      } else if (track.kind === "audio") {
        track.source = "microphone";
      }
      track.mediaStream = stream;

      return track;
    });
  }

  async createScreenTracks(options) {
    console.log("createScreenTracks", options);

    if (navigator.mediaDevices.getDisplayMedia === undefined) {
      throw new Error("getDisplayMedia not supported");
    }

    if (options.resolution === undefined && !isSafari17()) {
      options.resolution = {
        width: 640,
        height: 360,
        frameRate: 3,
        aspectRatio: "medium",
      };
    }

    const constraints = screenCaptureToDisplayMediaStreamOptions(options);
    const stream = await navigator.mediaDevices.getDisplayMedia(constraints);

    const tracks = stream.getVideoTracks();

    if (tracks.length === 0) {
      throw new TrackInvalidError("no video track found");
    }

    const screenVideo = new Track(tracks[0], "video");
    screenVideo.source = "screen_share";

    const localTracks = [screenVideo];

    if (stream.getAudioTracks().length > 0) {
      this.emit("audioStreamAcquired");
      const screenAudio = new Track(stream.getAudioTracks()[0], "audio");
      screenAudio.source = "screen_share_audio";
      localTracks.push(screenAudio);
    }

    return localTracks;
  }

  onTrackMuted(track) {
    console.log("onTrackMuted", track.mediaStreamID);

    this.emit("track.muted", track);
  }

  onTrackUnmuted(track) {
    console.log("onTrackUnmuted", track.mediaStreamID);

    this.emit("track.unmuted", track);
  }

  setMicrophoneEnabled(enabled, options) {
    return this.setTrackEnabled("microphone", enabled, options);
  }

  setCameraEnabled(enabled, options) {
    return this.setTrackEnabled("camera", enabled, options);
  }

  setScreenShareEnabled(enabled, options) {
    return this.setTrackEnabled("screen_share", enabled, options);
  }

  async setTrackEnabled(source, enabled = true, options) {
    console.log("setTrackEnabled", source, enabled);

    let track = this.getTrack(source);
    if (enabled) {
      if (track) {
        await track.unmute(); // ?
      } else {
        let localTracks = null;
        try {
          switch (source) {
            case "camera":
              localTracks = await this.createTracks({
                audio: false,
                video: true,
              });
              break;
            case "microphone":
              localTracks = await this.createTracks({
                audio: true,
                video: false,
              });
              break;
            case "screen_share":
              localTracks = await this.createScreenTracks({ ...options });
              break;
            default:
              throw new TrackInvalidError(source);
          }
          for (const localTrack of localTracks) {
            this.addTrack(localTrack);
          }
          [track] = localTracks;
        } catch (e) {
          localTracks?.forEach((tr) => {
            tr.stop();
          });
          if (e instanceof Error && !(e instanceof TrackInvalidError)) {
            this.emit("mediaDevicesError", e);
          }
          throw e;
        }
      }
    } else {
      if (track) {
        if (source === "screen_share") {
          track = await this.removeTrack(track);
          const screenAudioTrack = this.getTrack("screen_share_audio");
          if (screenAudioTrack) {
            this.removeTrack(screenAudioTrack);
          }
        } else {
          await track.mute();
        }
      }
    }

    return track;
  }

  async enableCameraAndMicrophone() {
    const tracks = await this.createTracks({
      audio: true,
      video: true,
    });

    tracks.map((track) => this.addTrack(track));
  }

  getTrack(source) {
    for (const [, track] of this.tracks) {
      if (track.source === source) {
        return track;
      }
    }
  }

  addTrack(track) {
    console.log("addTrack", track.mediaStreamID);

    track.on("muted", this.onTrackMuted);
    track.on("unmuted", this.onTrackUnmuted);

    this.tracks.set(track.mediaStreamID, track);

    switch (track.kind) {
      case "audio":
        this.audioTracks.set(track.mediaStreamID, track);
        break;
      case "video":
        this.videoTracks.set(track.mediaStreamID, track);
        break;
    }

    this.emit("track.added", track);
  }

  removeTrack(track) {
    console.log("removeTrack", track.mediaStreamID);

    track.off("track.muted", this.onTrackMuted);
    track.off("track.unmuted", this.onTrackUnmuted);
    track.stop();

    this.tracks.delete(track.mediaStreamID);
    switch (track.kind) {
      case "audio":
        this.audioTracks.delete(track.mediaStreamID);
        break;
      case "video":
        this.videoTracks.delete(track.mediaStreamID);
        break;
      default:
        break;
    }

    this.emit("track.removed", track);
  }

  get isCameraEnabled() {
    const track = this.getTrack("camera");
    return !(track?.isMuted ?? true);
  }

  get isMicrophoneEnabled() {
    const track = this.getTrack("microphone");
    return !(track?.isMuted ?? true);
  }

  get isScreenShareEnabled() {
    const track = this.getTrack("screen_share");
    return !!track;
  }
}
