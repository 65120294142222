<template>
  <video ref="videoEl" v-bind="$attrs" :muted="true"></video>
</template>

<script setup>
const props = defineProps({
  track: {
    type: Object,
    required: false,
  },
});

const videoEl = ref();

watch(
  () => props.track,
  (track, oldTrack) => {
    if (track) {
      track.attach(videoEl.value);
    }
    if (oldTrack) {
      oldTrack.detach(videoEl.value);
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  if (videoEl.value && props.track) {
    props.track.detach(videoEl.value);
  }
});
</script>
