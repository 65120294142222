import SpeechRecognition from "~/utils/speech-recognition/SpeechRecognition";
import Meet from "~/utils/meet/Meet";

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      meet: new Meet(),
      speechRecognition: SpeechRecognition,
    },
  };
});
