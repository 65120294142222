<template>
  <Teleport to="body">
    <v-scale-transition>
      <v-sheet
        v-show="meetStore.minimized"
        position="fixed"
        location="bottom"
        style="z-index: 999"
        class="mb-6 rounded-lg py-3 px-4"
        theme="dark"
        elevation="4"
      >
        <div class="d-flex align-center">
          <v-badge color="success" inline class="mr-3"></v-badge>
          <div style="min-width: 160px">
            <div class="text-subtitle-2 mb-1" style="line-height: 1">
              {{ userName }}
            </div>
            <MeetCountdown
              class="text-subtitle-1 font-weight-bold"
              style="line-height: 1"
            />
          </div>
          <v-spacer />
          <v-btn
            class="text-none text-subtitle-2"
            color="primary"
            @click="openMeetDialog"
            >Toplantıya Dön</v-btn
          >
        </div>
      </v-sheet>
    </v-scale-transition>
  </Teleport>
</template>

<script setup>
import MeetCountdown from "./MeetCountdown.vue";

const meetStore = useMeetStore();

const userName = computed(() => meetStore.info?.title);

function openMeetDialog() {
  meetStore.minimized = false;
}
</script>

<style></style>
