import { default as _91hash_93yYRRtKlIfTMeta } from "/opt/buildhome/repo/pages/assessment/[hash].vue?macro=true";
import { default as forgot_45passwordUaF9oDbyV0Meta } from "/opt/buildhome/repo/pages/auth/forgot-password.vue?macro=true";
import { default as loginLsrADI0A5CMeta } from "/opt/buildhome/repo/pages/auth/login.vue?macro=true";
import { default as registerPJjcn4pY50Meta } from "/opt/buildhome/repo/pages/auth/register.vue?macro=true";
import { default as _91hash_93i0y7NPwbGAMeta } from "/opt/buildhome/repo/pages/auth/reset-password/[hash].vue?macro=true";
import { default as _91number_93_45page6ODxYwTNzWMeta } from "/opt/buildhome/repo/pages/exam/[number]-page.vue?macro=true";
import { default as company0MOtgH03GSMeta } from "/opt/buildhome/repo/pages/exam/company.vue?macro=true";
import { default as inboxugt0bJJIUnMeta } from "/opt/buildhome/repo/pages/exam/inbox.vue?macro=true";
import { default as meetggHLKACzcRMeta } from "/opt/buildhome/repo/pages/exam/meet.vue?macro=true";
import { default as plansBQNPKdaLvnMeta } from "/opt/buildhome/repo/pages/exam/plans.vue?macro=true";
import { default as workersJ5MCL5FZDKMeta } from "/opt/buildhome/repo/pages/exam/workers.vue?macro=true";
import { default as workspbVq4bKxQGMeta } from "/opt/buildhome/repo/pages/exam/works.vue?macro=true";
import { default as examMjqMe6mCyVMeta } from "/opt/buildhome/repo/pages/exam.vue?macro=true";
import { default as _91number_93_45pageWEiKfinligMeta } from "/opt/buildhome/repo/pages/examo/[hash]/[number]-page.vue?macro=true";
import { default as companyIVuwcqqaRoMeta } from "/opt/buildhome/repo/pages/examo/[hash]/company.vue?macro=true";
import { default as inboxMTemEnb9F4Meta } from "/opt/buildhome/repo/pages/examo/[hash]/inbox.vue?macro=true";
import { default as meetdHBxXDSb1nMeta } from "/opt/buildhome/repo/pages/examo/[hash]/meet.vue?macro=true";
import { default as plansEYnXwIyfg1Meta } from "/opt/buildhome/repo/pages/examo/[hash]/plans.vue?macro=true";
import { default as qadzNTrcg7dtMeta } from "/opt/buildhome/repo/pages/examo/[hash]/qa.vue?macro=true";
import { default as schedule0kUHtCbiUCMeta } from "/opt/buildhome/repo/pages/examo/[hash]/schedule.vue?macro=true";
import { default as startkCru7GamiNMeta } from "/opt/buildhome/repo/pages/examo/[hash]/start.vue?macro=true";
import { default as workersvfG8T5eBhrMeta } from "/opt/buildhome/repo/pages/examo/[hash]/workers.vue?macro=true";
import { default as worksghSMDiB001Meta } from "/opt/buildhome/repo/pages/examo/[hash]/works.vue?macro=true";
import { default as _91hash_93UhFvJha6oCMeta } from "/opt/buildhome/repo/pages/examo/[hash].vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91hash_93o7Vwi2vuyZMeta } from "/opt/buildhome/repo/pages/invite/[hash].vue?macro=true";
export default [
  {
    name: "assessment-hash",
    path: "/assessment/:hash()",
    meta: _91hash_93yYRRtKlIfTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/assessment/[hash].vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordUaF9oDbyV0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginLsrADI0A5CMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/login.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerPJjcn4pY50Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password-hash",
    path: "/auth/reset-password/:hash()",
    component: () => import("/opt/buildhome/repo/pages/auth/reset-password/[hash].vue")
  },
  {
    name: "exam",
    path: "/exam",
    meta: examMjqMe6mCyVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/exam.vue"),
    children: [
  {
    name: "exam-number-page",
    path: ":number()-page",
    component: () => import("/opt/buildhome/repo/pages/exam/[number]-page.vue")
  },
  {
    name: "exam-company",
    path: "company",
    component: () => import("/opt/buildhome/repo/pages/exam/company.vue")
  },
  {
    name: "exam-inbox",
    path: "inbox",
    component: () => import("/opt/buildhome/repo/pages/exam/inbox.vue")
  },
  {
    name: "exam-meet",
    path: "meet",
    component: () => import("/opt/buildhome/repo/pages/exam/meet.vue")
  },
  {
    name: "exam-plans",
    path: "plans",
    component: () => import("/opt/buildhome/repo/pages/exam/plans.vue")
  },
  {
    name: "exam-workers",
    path: "workers",
    component: () => import("/opt/buildhome/repo/pages/exam/workers.vue")
  },
  {
    name: "exam-works",
    path: "works",
    component: () => import("/opt/buildhome/repo/pages/exam/works.vue")
  }
]
  },
  {
    name: "examo-hash",
    path: "/examo/:hash()",
    meta: _91hash_93UhFvJha6oCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/examo/[hash].vue"),
    children: [
  {
    name: "examo-hash-number-page",
    path: ":number()-page",
    component: () => import("/opt/buildhome/repo/pages/examo/[hash]/[number]-page.vue")
  },
  {
    name: "examo-hash-company",
    path: "company",
    component: () => import("/opt/buildhome/repo/pages/examo/[hash]/company.vue")
  },
  {
    name: "examo-hash-inbox",
    path: "inbox",
    component: () => import("/opt/buildhome/repo/pages/examo/[hash]/inbox.vue")
  },
  {
    name: "examo-hash-meet",
    path: "meet",
    component: () => import("/opt/buildhome/repo/pages/examo/[hash]/meet.vue")
  },
  {
    name: "examo-hash-plans",
    path: "plans",
    component: () => import("/opt/buildhome/repo/pages/examo/[hash]/plans.vue")
  },
  {
    name: "examo-hash-qa",
    path: "qa",
    meta: qadzNTrcg7dtMeta || {},
    component: () => import("/opt/buildhome/repo/pages/examo/[hash]/qa.vue")
  },
  {
    name: "examo-hash-schedule",
    path: "schedule",
    meta: schedule0kUHtCbiUCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/examo/[hash]/schedule.vue")
  },
  {
    name: "examo-hash-start",
    path: "start",
    meta: startkCru7GamiNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/examo/[hash]/start.vue")
  },
  {
    name: "examo-hash-workers",
    path: "workers",
    component: () => import("/opt/buildhome/repo/pages/examo/[hash]/workers.vue")
  },
  {
    name: "examo-hash-works",
    path: "works",
    component: () => import("/opt/buildhome/repo/pages/examo/[hash]/works.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "invite-hash",
    path: "/invite/:hash()",
    component: () => import("/opt/buildhome/repo/pages/invite/[hash].vue")
  }
]