import { defineStore } from "pinia";
import { clearTimeout, setTimeout } from "worker-timers";

function calculateRemainingTime(startDate, durationMinutes) {
  if (!startDate || !durationMinutes) {
    throw new Error("Hem başlangıç tarihi hem de süre gereklidir.");
  }

  const now = new Date();
  const start = new Date(startDate);

  if (isNaN(start.getTime()) || typeof durationMinutes !== "number") {
    throw new Error("Geçerli bir başlangıç tarihi ve sayı türünde süre girin.");
  }

  const durationMs = durationMinutes * 60 * 1000;
  const endDate = new Date(start.getTime() + durationMs);

  const remainingMs = endDate - now;

  return remainingMs > 0 ? remainingMs : 0;
}

let timeoutId;

export const useMeetStore = defineStore({
  id: "meetStore",
  state: () => ({
    status: "idle",
    meeting: false,
    minimized: false,
    startedAt: null,
    endDate: null,
    info: null,
  }),
  actions: {
    init() {},
    checkPermissions() {},
    async join(meetId) {
      if (meetId == this.info?.id) {
        this.minimized = false;
        return;
      }

      if (this.meeting) {
        useSnackbar().error("Already connected");
        return;
      }

      this.status = "starting";

      try {
        await useMeet().start();

        const result = await client(`/api/applicant/case/meet/${meetId}/join`, {
          method: "POST",
        });

        if (result.status == "completed") {
          this.status = "completed";
          useMeet().stop();
          useSnackbar().error("Meeting has ended");
          return;
        }

        this.meeting = true;
        this.minimized = false;
        this.status = "started";
        this.startedAt = new Date(result.started_at);
        this.info = result;

        if (result.duration > 0) {
          this.endedAt = new Date(
            this.startedAt.getTime() + result.duration * 60 * 1000
          );

          const remainingTime = calculateRemainingTime(
            this.startedAt,
            meetInfo.duration
          );

          clearTimeout(timeoutId);

          if (remainingTime > 0) {
            timeoutId = setTimeout(() => {
              this.end();
            }, remainingTime);
          }
        }

        return result;
      } catch (error) {
        this.status = "error";
        useMeet().stop();
        useSnackbar().error(error.message);
      }
    },
    async start(meetInfo) {
      if (meetInfo.id == this.info?.id) {
        this.minimized = false;
        return Promise.resolve();
      }

      if (meetInfo.status == "completed") {
        return Promise.reject(new Error("Meeting has ended"));
      }

      if (
        meetInfo.status == "started" &&
        calculateRemainingTime(meetInfo.started_at, meetInfo.duration) == 0
      ) {
        return Promise.reject(new Error("Meeting has expired"));
      }

      if (this.meeting) {
        return Promise.reject(new Error("Already connected"));
      }

      this.startedAt = null;
      this.endDate = null;
      this.meeting = true;
      this.minimized = false;
      this.status = "starting";
      this.info = meetInfo;

      if (meetInfo.status == "waiting") {
        const client = useSanctumClient();

        await client(`/api/applicant/case/meet/${meetInfo.id}/join`, {
          method: "POST",
        });
      }

      await useMeet().start();

      this.status = "started";
      this.startedAt = meetInfo?.started_at
        ? new Date(meetInfo.started_at)
        : new Date();
      this.endDate = meetInfo?.end_date
        ? new Date(meetInfo?.end_date)
        : new Date(this.startedAt.getTime() + meetInfo.duration * 60 * 1000);

      const remainingTime = calculateRemainingTime(
        this.startedAt,
        meetInfo.duration
      );

      clearTimeout(timeoutId);

      if (remainingTime > 0) {
        timeoutId = setTimeout(() => {
          this.end();
        }, remainingTime);
      }

      return Promise.resolve();
    },
    async end() {
      if (!this.meeting) {
        return;
      }

      try {
        this.status = "ending";
        await useMeet().stop();
        this.status = "completed";
        useSnackbar().success("Meeting has ended");
        this.meeting = false;
        this.startedAt = null;
        this.endDate = null;
        this.info = null;
      } catch (error) {
        useSnackbar().error(error.message);
      }
    },
  },
});
