<template>
  <div class="meet--local-camera" :style="styles">
    <VideoTrack :track="cameraTrack" />
    <v-sheet
      v-if="showUsername"
      location="bottom left"
      theme="dark"
      rounded="lg"
      class="px-2 py-1 ma-2 text-subtitle-2"
      position="absolute"
    >
      <v-icon color="green" size="17">mdi-microphone</v-icon>
      Yasin
    </v-sheet>
  </div>
</template>

<script setup>
import VideoTrack from "./VideoTrack.vue";

const props = defineProps({
  aspectRatio: {
    type: Number,
    default: () => 3 / 4,
  },
  size: {
    type: [Number, String],
    default: () => "100%",
  },
  showUsername: {
    type: Boolean,
    default: () => true,
  },
});

const meetStore = useMeetStore();
const tracks = useTracks(["camera"]);

const cameraTrack = computed(() => tracks.value?.[0]);

const styles = computed(() => ({
  aspectRatio: props.aspectRatio,
  width: props.size > 0 ? `${props.size}px` : props.size,
}));
</script>

<style>
.meet--local-camera {
  overflow: hidden;
}

.meet--local-camera > div {
  background-color: rgba(30, 28, 28, 0.7);
}

.meet--local-camera > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
