export function useCountdown(
  targetDate,
  autoStart = false,
  format = "hh:mm:ss"
) {
  let timer = null;
  const time = ref(0);
  const isCompleted = ref(false);

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const target = new Date(targetDate.value).getTime();
    return Math.max(Math.floor((target - now) / 1000), 0);
  };

  const timeFormatted = computed(() => {
    const hours = Math.floor(time.value / 3600);
    const minutes = Math.floor((time.value % 3600) / 60);
    const seconds = time.value % 60;

    return format
      .replace("hh", hours.toString().padStart(2, "0"))
      .replace("mm", minutes.toString().padStart(2, "0"))
      .replace("ss", seconds.toString().padStart(2, "0"));
  });

  const start = () => {
    time.value = calculateTimeLeft();
    if (time.value <= 0) {
      isCompleted.value = true;
      return;
    }
    isCompleted.value = false;
    if (timer) return;
    timer = setInterval(() => {
      const remainingTime = calculateTimeLeft();
      if (remainingTime > 0) {
        time.value = remainingTime;
      } else {
        clearInterval(timer);
        timer = null;
        isCompleted.value = true;
      }
    }, 1000);
  };

  const stop = () => {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  };

  onUnmounted(() => {
    stop();
  });

  watch(
    targetDate,
    (date) => {
      if (date && autoStart) {
        stop();
        start();
      }
    },
    { immediate: true }
  );

  return {
    time,
    timeFormatted,
    isCompleted,
    start,
    stop,
  };
}
