export const useAudioLevel = () => {
  const { $meet } = useNuxtApp();

  const audioLevel = ref(0);

  const setAudioLevel = (level: number) => {
    audioLevel.value = level;
  };

  onMounted(() => {
    $meet.on("audioLevel", setAudioLevel);
  });

  onUnmounted(() => {
    $meet.off("audioLevel", setAudioLevel);
  });

  return audioLevel;
};
