export const useTracks = (sources) => {
  const { $meet } = useNuxtApp();

  const tracks = ref([]);

  function refreshTracks() {
    tracks.value = Array.from($meet.localParticipant.tracks.values()).filter(
      (track) => sources.includes(track.source)
    );
  }

  onMounted(() => {
    refreshTracks();
    $meet.localParticipant.on("track.added", refreshTracks);
    $meet.localParticipant.on("track.removed", refreshTracks);
  });

  onUnmounted(() => {
    $meet.localParticipant.off("track.added", refreshTracks);
    $meet.localParticipant.off("track.removed", refreshTracks);
  });

  return tracks;
};
