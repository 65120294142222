<template>
  <v-layout>
    <v-app-bar flat class="px-md-3 border-b">
      <template v-slot:prepend> Logo </template>
      <v-btn
        variant="text"
        class="text-medium-emphasis text-body-2 text-capitalize app-btn px-3"
        >Menu1</v-btn
      >
      <v-btn
        variant="text"
        class="text-medium-emphasis text-body-2 text-capitalize app-btn px-3"
        >Menu2</v-btn
      >
      <template v-slot:append>
        <v-btn @click="toggle()">toggle theme</v-btn>
        <v-menu min-width="200px" rounded>
          <template v-slot:activator="{ props }">
            <v-btn icon v-bind="props">
              <v-avatar color="brown">
                <span class="text-h5">{{ user.initials }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <div class="mx-auto text-center">
                <v-avatar color="brown">
                  <span class="text-h5">{{ user.initials }}</span>
                </v-avatar>
                <h3>{{ user.fullName }}</h3>
                <p class="text-caption mt-1">
                  {{ user.email }}
                </p>
                <v-divider class="my-3"></v-divider>
                <v-btn variant="text" rounded> Edit Account </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn variant="text" rounded> Disconnect </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-app-bar>

    <v-main>
      <NuxtPage />
    </v-main>
  </v-layout>
</template>

<script setup>
const { toggle } = useCustomTheme();

const user = ref({
  fullName: "John Doe",
  email: "example@gmail.com",
  initials: "JD",
});
</script>

<style></style>
